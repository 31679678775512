





























import { Component } from 'vue-property-decorator';
import CompanyInformation from '@/components/company/CompanyInformation.vue';
import CompanyCmsProfileBaseWidget from '@/components/company-cms/CompanyCmsProfileBaseWidget.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import MyProfileInputVideo from '@/components/MyProfileInputVideo.vue';
import ToastActionType from '@/utils/enums/ToastActionType';
import Exhibitor from '@/models/graphql/Exhibitor';
import { namespace } from 'vuex-class';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import SlotTypeEnum from '@/utils/enums/SlotTypeEnum';
import EntityType from '@/utils/enums/EntityType';

const exhibitorStore = namespace('ExhibitorStore');
const salesPackageLimitStore = namespace('SalesPackageLimitStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: {
    MyProfileInputVideo,
    CompanyInformation,
  },
})
export default class CompanyCmsProfileInformationWidget extends mixins(CompanyCmsProfileBaseWidget, VueRegisterStoreWidget) {
  @exhibitorStore.Action
  private updateExhibitor!: (payload: {
    uid: string;
    attributes: Partial<Exhibitor>;
  }) => Promise<Exhibitor | undefined>;

  @exhibitorStore.Mutation
  private setAdminPanelExhibitorVideoPresentationS3Url!: (exhibitor: Exhibitor) => void;

  @permissionManagerStore.Getter
  private canManageCompanyContent!: (companyUid: string) => boolean;

  @salesPackageLimitStore.Action
  private filterSlots!: (payload: { exhibitorUid: string; code: string }) => Promise<{
    type: string;
    limit: number;
  }[]>;

  private FeatureKeys = FeatureKeys;

  private videoPresentationUrl = '';

  private limit = 0;

  private EntityType = EntityType;

  private get canManageCompanyInformation(): boolean {
    if (this.adminPanelExhibitor) {
      return this.canManageCompanyContent(this.adminPanelExhibitor.uid);
    }
    return false;
  }

  private get canShowVideoComponent(): boolean {
    return this.limit > 0 || this.limit === -1;
  }

  created(): void {
    this.setDataConfig();
  }

  mounted(): void {
    if (this.adminPanelExhibitor && this.adminPanelExhibitor.videoPresentationS3Url) {
      this.videoPresentationUrl = this.adminPanelExhibitor.videoPresentationS3Url;
    }
    if (this.adminPanelExhibitor) {
      this.filterSlots({
        exhibitorUid: this.adminPanelExhibitor.uid,
        code: SlotTypeEnum.COMPANY_VIDEO_PRESENTATION_SLOT,
      })
        .then((response) => {
          if (response && response[0]) {
            this.limit = response[0].limit;
          }
        });
    }
  }

  private setVideoPresentation(url: string): void {
    this.videoPresentationUrl = url.trim();
    if (this.adminPanelExhibitor && url === '') {
      this.updateExhibitor({
        uid: this.adminPanelExhibitor.uid,
        attributes: {
          uid: this.adminPanelExhibitor.uid,
          videoPresentationS3Url: '',
        },
      })
        .then((response) => {
          if (response) {
            this.setAdminPanelExhibitorVideoPresentationS3Url(response);
          }
        });
      this.showToast(ToastActionType.UPDATE_COMPANY_INFORMATION);
    }
  }

  private onDeleteVideoClick(): void {
    this.setVideoPresentation('');
  }
}
